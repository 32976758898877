<template>
  <div class="column">
    <p class="yogo-input-name" v-if="isShowLabel">{{curLabelName}}</p>
    <input type="text" v-model="modelCode" @blur="sendCode"/>
    <i class="btnicon btnimgdown"></i>
  </div>
</template>
<script>

import {inputMixins} from '@/views/mixins'
export default {
  mixins: [inputMixins]
}
</script>